import request from '@/utils/request'

export function productionProgressQuery(params) {
    return request({
        url: '/productionSchedule/production/progress/record/list',
        method: 'get',
        params
    })
}

export function productionProgressList(params) {
    return request({
        url: '/productionSchedule/list',
        method: 'get',
        params
    })
}