<template>
    <div class="content-box">
        <div class="container">
            <el-button class="reBack" v-show="this.progressId !== undefined" @click="$router.push('/Data/contract')" type="primary"
                >返回</el-button
            >

            <el-breadcrumb style="margin-bottom: 40px" separator="/">
                <el-breadcrumb-item>协同管理</el-breadcrumb-item>
                <el-breadcrumb-item>协同制造</el-breadcrumb-item>
                <el-breadcrumb-item>生产进度管理</el-breadcrumb-item>
            </el-breadcrumb>

            <el-table :data="tableData" border max-height="600">
                <el-table-column align="center" width="50" type="expand">
                    <template slot-scope="scope">
                        <el-table :data="scope['row']['productionSchedule']['value']">
                            <el-table-column prop="name" label="任务负责人" align="center"> </el-table-column>
                            <el-table-column prop="timeCreated" label="完成时间" align="center"> </el-table-column>
                            <el-table-column prop="num" label="完成数量" align="center"> </el-table-column>
                            <el-table-column align="center" label="操作">
                                <template slot-scope="sco">
                                    <el-button style="margin-right: 12px" size="mini" @click="resetProduct(sco.row, scope)">
                                        编辑
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                </el-table-column>
                <el-table-column prop="productionSchedule.taskName" align="center" label="任务名称"> </el-table-column>
                <el-table-column prop="schedule" align="center" label="任务进度"> </el-table-column>
                <el-table-column prop="productionSchedule.taskCount" align="center" label="总数量"> </el-table-column>
                   <el-table-column  align="center" label="剩余数量"> 
                     <template  slot-scope="scope">
                        <span>{{scope.row.productionSchedule.taskCount-scope.row.productionSchedule.makeNum}}</span>
                     </template>
                   </el-table-column>
                <el-table-column prop="productionSchedule.timeFound" align="center" label="任务起始时间"> </el-table-column>
                <el-table-column prop="productionSchedule.timeAbort" align="center" label="任务截止时间"> </el-table-column>
                <el-table-column prop="userInfo.name" align="center" label="任务负责人"> </el-table-column>
                <el-table-column prop="userInfo.name" align="center" label="操作" v-if="userType=='供应商'" >
                    <template slot-scope="scope">
                        <el-button style="margin-right: 12px" size="mini" @click="resetSpeedProduct(scope.row)"> 更新生产进度 </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- </el-col> -->
            <!-- <el-col :span="6">
        <el-card>
          <div slot="header" class="clearfix">
            <h3>当前生产进度</h3>
          </div>
          <div class="progress-wrapper">
            <el-progress type="circle" :percentage="75"></el-progress>
          </div>
        </el-card>

      </el-col> -->
            <!-- </el-row> -->
            <!--    <div id="mains" class="mains"></div>-->
            <!--    <div id="main" class="mains"></div>-->

            <el-dialog title="新增" :visible.sync="dialogVisible" :before-close="Cancel">
                <el-form ref="elForms" :model="productionFrom" label-position="left">
                    <el-form-item label="任务名称" required>
                        <el-input
                            v-model="productionFrom.taskName"
                            placeholder="请输入任务名称"
                            clearable
                            :style="{ width: '100%' }"
                        ></el-input>
                    </el-form-item>

                    <el-form-item label="任务创建时间" disabled required>
                        <el-date-picker
                            v-model="productionFrom.timeFound"
                            format="yyyy-MM-dd HH:mm"
                            value-format="yyyy-MM-dd HH:mm"
                            :style="{ width: '100%' }"
                            placeholder="请选择开始时间"
                            clearable
                        ></el-date-picker>
                    </el-form-item>
                    <el-form-item label="任务截止时间" required>
                        <el-date-picker
                            v-model="productionFrom.timeAbort"
                            format="yyyy-MM-dd HH:mm"
                            value-format="yyyy-MM-dd HH:mm"
                            :style="{ width: '100%' }"
                            placeholder="请选择截止时间"
                            clearable
                        ></el-date-picker>
                    </el-form-item>
                    <el-form-item label="任务负责人" required>
                        <el-select v-model="productionFrom.taskPerson" placeholder="请选择任务负责人" clearable :style="{ width: '100%' }">
                            <el-option
                                v-for="(item, index) in personInChargeOptions"
                                :key="index"
                                :label="item.label"
                                :value="item.value"
                                :disabled="item.disabled"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="任务总量" required>
                        <el-input-number v-model="productionFrom.taskCount" placeholder="任务总量" :step="100"></el-input-number>
                    </el-form-item>
                    <!-- <el-form-item
          label="生产进度"
          v-model="productionFrom.schedule"
          required
        >
          <el-slider v-model="productionFrom.schedule" :max="1000"></el-slider>
        </el-form-item> -->
                </el-form>
                <div slot="footer" class="footer">
                    <el-button @click="Cancel">取 消</el-button>
                    <el-button type="primary" @click="SendData(productionFrom)">确 定</el-button>
                </div>
            </el-dialog>

            <el-dialog title="编辑" :visible.sync="resetdialogVisible" :before-close="Cancel">
                <el-form ref="elForm" :model="resetFrom" label-position="left">
                    <el-form-item label="负责人" required>
                        <el-input
                            v-model="resetFrom.name"
                            placeholder="请输入负责人"
                            clearable
                            disabled
                            :style="{ width: '100%' }"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="任务创建时间" disabled required>
                        <el-date-picker
                            disabled
                            v-model="resetFrom.timeFound"
                            format="yyyy-MM-dd HH:mm"
                            value-format="yyyy-MM-dd HH:mm"
                            :style="{ width: '100%' }"
                            placeholder="请选择开始时间"
                            clearable
                        ></el-date-picker>
                    </el-form-item>
                    <el-form-item label="完成数量" required prop="num">
                        <el-input-number v-model="resetFrom.num" :min="1" :max="maxNum" label="描述文字"></el-input-number>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="footer">
                    <el-button @click="Cancel">取 消</el-button>
                    <el-button type="primary" @click="ResetData(resetFrom)">确 定</el-button>
                </div>
            </el-dialog>

            <el-dialog title="更新进度" :visible.sync="newProdialogVisible" width="400px" :before-close="Cancel">
                <el-form ref="elForm" :model="speedResetFrom" label-position="left">
                    <el-form-item label="生产数量"   prop="num">
                        <el-input-number v-model="speedResetFrom.makeNum" :min="1" :max="maxSpeedNum" label="描述文字"></el-input-number>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="speedFooter">
                    <el-button @click="Cancel">取 消</el-button>
                    <el-button type="primary" @click="speedResetData(speedResetFrom)">确 定</el-button>
                </div>
            </el-dialog>
            <el_pagination :total="total" :num="num" @page-limit="page_limit"></el_pagination>
        </div>
    </div>
</template>

<script>
// import * as echarts from "echarts";

import { ProductionList, resetProductionschedule, deleteProductionschedule, ProListUpdate } from '@/api/product';
import { productionProgressQuery, productionProgressList } from '@/api/productionProgress';
import { produceSpeed } from '@/api/produce';
// import ProductionProcessDialog from '@/views/collaborative/manufacture/ProductionProgressDialog'
export default {
    name: '',
    components: {},
    data() {
        return {
            maxNum: null,
            maxSpeedNum: null,
            dialogVisible: false,
            resetdialogVisible: false,
            newProdialogVisible: false,
            tableData: [], // 后端获取到的数据
            productionFrom: {},
            resetFrom: {},
            speedResetFrom: {},
            personInChargeOptions: [
                {
                    index: 1,
                    label: '负责人一',
                    value: '负责人一'
                },
                {
                    index: 2,
                    label: '负责人二',
                    value: '负责人二'
                },
                {
                    index: 3,
                    label: '负责人三',
                    value: '负责人三'
                }
            ],
            progressId: '',
            schedule: 0,
            data: {},
            pageSize: 10,
            currentPage: 1,
            total: 0,
            userType: localStorage.getItem('userType'),
            num: 0
        };
    },
    mounted() {
        // this.tubiao();
        // this.tubiaos();
        this.progressId = this.$route.query.id;
        console.log(this.progressId);
        this.getFileList();

        // console.log(this.role);
    },
    methods: {
        // 分页子集传过来的数据  page = 页码  limit = 条数
        // 分页子集传过来的数据  page = 页码  limit = 条数
        page_limit(list) {
            console.log(list);
            let { page, limit } = list;
            this.currentPage = page;
            this.pageSize = limit;
            this.tableData = [];
            this.getFileList();
        },

        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
        },
        // 获取表
        async getFileList() {
            let limit = this.pageSize;
            let page = this.currentPage;
            let contractId = this.progressId;
            if (contractId == undefined || contractId == '') {
                contractId = null;
            }
            const res = await ProductionList({ limit, page, contractId });
            //   console.log(res.data);
            const data = res['data'];
            data.forEach((item) => {
                this.fetchProductionProgressQuery(item['productionSchedule']['id']).then((production) => {
                    item['productionSchedule']['value'] = [];
                    item['productionSchedule']['value'] = production;
                });
            });
            this.tableData = res.data;
            this.total = res['total'];
        },
        async fetchProductionProgressQuery(item) {
            const response = await productionProgressQuery({
                productionScheduleId: item
            });
            return response['data'];
        },
        async deleteFile(row) {
            let id = row.id;

            const res = await deleteProductionschedule({
                id
            });
            if (res.code === 200) {
                this.$message.success('删除成功');
                this.getFileList();
            } else {
                this.$message.error('删除失败');
            }
            await this.getFileList();
        },

        //取消
        Cancel() {
            this.dialogVisible = false;
            this.productionFrom = {};
            this.resetdialogVisible = false;
            this.newProdialogVisible = false;
        },

        //编辑总进度
        resetSpeedProduct(row) {
            this.newProdialogVisible = true;
            this.maxSpeedNum = row.productionSchedule.taskCount - row.productionSchedule.makeNum;
            this.speedResetFrom.id = row.productionSchedule.id;
        },
        async speedResetData(speedResetFrom) {
            let res = await produceSpeed(this.speedResetFrom);
            if (res.code == 200) {
                this.$message.success('更新成功');
                this.getFileList();
                this.newProdialogVisible = false;
                this.speedResetFrom = {};
            } else {
                this.newProdialogVisible = false;
                this.$message.error(res.message);
            }
        },
        //编辑明细
        resetProduct(row, scope) {
            this.resetdialogVisible = true;
            console.log(row);
            console.log(scope.row);
            let data = {};
            data.id = row.id;
            data.name = row.name;
            data.taskCount = scope.row.productionSchedule.taskCount;
            data.makeNum = scope.row.productionSchedule.makeNum;

            data.timeFound = row.timeCreated;
            data.num = row.num;
            this.resetFrom = JSON.parse(JSON.stringify(data));
            this.maxNum = data.taskCount - row.makeNum + row.num;
        },
        async ResetData(resetFrom) {
            let data = {};
            data.num = this.resetFrom.num;
            data.id = this.resetFrom.id;
            let res = await ProListUpdate(data);
            if (res.code == 200) {
                this.$message.success('编辑成功');
                this.getFileList();
                this.total = res.total;
                this.resetdialogVisible = false;
                this.resetFrom = {};
            } else {
                this.$message.error(res.message);
                 this.resetdialogVisible = false;
                this.resetFrom = {};
            }
        }
    }
};
</script>


<style lang="scss" scoped>
.button-gutter {
    margin: 20px 0;
}
::v-deep .el-slider {
    margin-left: 30%;
    width: 40%;
}

.progress-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
.mains {
    width: 1200px;
    height: 280px;
}
::v-deep .el-dialog__header {
    text-align: center;
}
.footer {
    width: 20%;

    margin: 0 auto;
}
.reBack {
    float: right;

    margin-top: 10px;
}
.speedFooter {
    width: 100%;
    margin: 0 auto;
    text-align: center;
}
</style>